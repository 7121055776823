<template>
  <div v-if="topCourts.length > 0" class="top-courts" :class="isMobile ? 'mobile' : ''">
    <h3 class="header">
      {{ $t('report.tops.court.title') }}
    </h3>
    <div class="list-header">
      <span class="row-number">
      {{ $t('report.tops.court.table.header.row') }}
      </span>
      <span class="name">
      {{ $t('report.tops.court.table.header.name') }}
      </span>
      <span class="price">
      {{ $t('report.tops.court.table.header.price') }}
      </span>
      <span v-if="isMobile" class="show">
      {{ $t('report.tops.court.table.header.show') }}
      </span>
    </div>
    <ul class="courts-list">
      <template v-for="(court, key) in topCourts">
        <li :key="key" class="court" @click="handleShowCourt(court)">
          <span class="row-number">
            {{ key + 1 }}
          </span>
          <span class="name">{{ court.name }}</span>
          <span class="price">
            {{ court.finalPrice }}
            <span class="currency">
              {{ $locale.currency() }}
            </span>
          </span>
          <span v-if="isMobile" class="show">
            <custom-icon icon="EYE" height="20px" width="20px" />
          </span>
        </li>
      </template>
    </ul>

    <!-- show court details prompt -->
    <vs-prompt
      class="medium-prompt p-0"
      :buttons-hidden="true"
      title=""
      :active.sync="showCourtPromptStatus"
      @close="showCourtPromptStatus = true">

      <div class="prompt-header p-3 w-full" :class="[!this.$vs.rtl ? 'rtl-only' : '']">
        <vs-row>
          <vs-col class="w-1/5 useral-font-weight-medium text-success cursor-pointer">
            <div v-if="checkUserPermissions('court.update')" @click="editCourtPromptStatus = true">
              <custom-icon icon="EDIT" color="warning"/>
            </div>
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/2 text-center useral-font-weight-bold text-md">
            <!--            پروفایل {{selectedCourt.name}}-->
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/5 text-right useral-font-weight-medium text-danger cursor-pointer">
            <div @click="showCourtPromptStatus = false">
              <custom-icon icon="TIMES-CIRCLE" color="danger"/>
            </div>
          </vs-col>
        </vs-row>
      </div>

      <div class="prompt-content">
        <template>
          <keep-alive>
            <court-profile :court-id="selectedCourt.id"/>
          </keep-alive>
        </template>
      </div>

    </vs-prompt>
  </div>
</template>

<script>
  import {getTopCourts} from "../../../../http/requests/reports";
  import {addComma, checkUserPermissions, getAvatarUrl, getStaticFileUrl} from "../../../../assets/js/functions";
  import CustomIcon from "../../../../components/customIcon/customIcon";
  import CourtProfile from "../../club/courts/profile/courtsProfile";

  export default {
    name: "topCourts",
    components: {CourtProfile, CustomIcon},
    data () {
      return {
        showCourtPromptStatus: false,
        selectedCourt: {},
        topCourts: []
      }
    },
    computed: {
      isMobile() {
        return this.$store.state.windowWidth <= this.$store.state.mobileScreen
      },
    },
    created () {
      this.getTopCourts()
    },
    methods: {
      checkUserPermissions(permission) {
        return checkUserPermissions(permission)
      },
      getTopCourts () {
        getTopCourts().then((response) => {
          const topCustomers = response.data.data
          topCustomers.forEach((item) => {
            this.topCourts.push({
              id: item.court.id,
              avatar: item.court.avatar ? getAvatarUrl(item.court.avatar) : getStaticFileUrl('/images/tennis-court.jpg'),
              name: item.court.name,
              finalPrice: addComma(item.final_price)
            })
          })
        })
      },

      handleShowCourt (court) {
        this.selectedCourt = court
        this.showCourtPromptStatus = true
      }
    }
  }
</script>

<style lang="scss" scoped>
  .top-courts {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    height: calc((100vh - 135px) / 2);
    min-height: 250px;
    padding: 0 15px;
    box-shadow: 0 0 10px -5px;
    border-radius: 1rem;
    overflow: hidden;

    .header {
      position: relative;
      font-size: 18px;
      text-align: left;
      padding: 10px 0 17px;
      margin-bottom: 10px;
      height: 45px;

      &:after {
        content: ' ';
        width: 50px;
        height: 5px;
        background-color: #48B4E0;
        display: block;
        margin: auto;
        border-radius: 0.5rem;
        position: absolute;
        bottom: 0;
        left: 0;
      }
    }

    .list-header {
      display: flex;
      align-items: center;
      color: #cdcdcd;
      border-bottom: 1px solid #cdcdcd;
      height: 40px;
      font-weight: 500;

      .row-number {
        width: 50px;
        text-align: center;
      }

      .name {
        display: flex;
        justify-content: center;
        text-align: center;
        width: calc((100% - 50px) / 2);
      }

      .price {
        text-align: center;
        width: calc((100% - 50px) / 2);
      }
    }

    .courts-list {
      height: calc(100% - 45px);
      overflow-y: auto;

      &::-webkit-scrollbar {
        display: block;
        border-radius: .2rem;
        width: 8px;
        background: #f9f9fd;
        right: 8px;
      }

      &::-webkit-scrollbar-thumb {
        display: block;
        border-radius: .2rem;
        background: #cecece;
      }

      .row-number {
        width: 50px;
        text-align: center;
      }

      .court-image {
        height: 37px;
        width: 37px;
        border-radius: 100%;
        overflow: hidden;
        margin-right: 10px;

        .image {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }

      .court {
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-align: center;
        padding: 5px;
        color: #000000;
        border-bottom: 1px solid #cecece;
        font-size: 14px;
        height: 40px;
        /*line-height: 40px;*/
        /*font-weight: 500;*/

        &:hover {
          cursor: pointer;
        }

        .name {
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          width: calc((100% - 50px) / 2);
        }

        .price {
          /*font-weight: 500;*/
          width: calc((100% - 50px) / 2);
          text-align: center;

          .currency {
            font-weight: 300;
            font-size: 10px;
          }
        }

        &:last-child {
          border-bottom: 0;
        }
      }
    }

    &.mobile {

      .list-header {
        .row-number {
          width: 50px;
        }

        .name {
          width: calc((100% - 100px) / 2);
        }

        .price {
          width: calc((100% - 100px) / 2);
        }

        .show {
          width: 50px;
        }
      }
      .court {
        .name {
          width: calc((100% - 100px) / 2);
        }

        .price {
          width: calc((100% - 100px) / 2);
        }

        .show {
          width: 50px;
          display: flex;
          justify-content: center;
        }
      }
    }
  }
</style>
